// components/CustomCard.js
import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const CustomCard = ({ children, width, padding, customStyles }) => {
  return (
    <Card
      elevation={3}
      sx={{
        width: "100%",
        borderRadius: 10,
        backgroundColor: "#F8F8F8",
        padding: padding || 3,
        ...customStyles, // Merge custom styles with default styles
      }}
    >
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default CustomCard;
