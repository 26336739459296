import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../contexts/AuthContext";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TermsAndConditions from "../DashBoard/TermsAndConditions";
import CustomButton from "../BaseComponents/CustomButton";
import CandidateCard from "../BaseComponents/CandidateCard";
import { fetchCandidateDetails } from "../../api";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setCandidateDetails,
  setSelectedTestDetails,
} from "../../redux/actions/dashboardActions";
import HandleApiError from "../../HandleApiError";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { candidateDetails } = useSelector((state) => state.dashboard);

  const { user, logout } = useAuth();
  const token = localStorage.getItem("token");
  const termsContainerRef = useRef(null);

  const navigate = useNavigate();
  const [showTermsDialog, setShowTermsDialog] = useState(false);

  const handleStartTest =
    (company_id, position_id, test_key, candidate_id) => () => {
      dispatch(
        setSelectedTestDetails({
          company_id,
          position_id,
          test_key,
          candidate_id,
        })
      );
      setShowTermsDialog(true);
    };

  const handleAcceptTerms = () => {
    setShowTermsDialog(false);
    navigate(`/testdetails`);
  };

  const handleDeclineTerms = () => {
    setShowTermsDialog(false);
  };

  useEffect(() => {
    if (token) {
      navigate("/dashboard"); // Or any other protected route
    } else {
      navigate("/home"); // Or any other protected route
    }
  }, [token]);

  useEffect(() => {
    if (user) {
      const candidateEmail = user.email;

      fetchCandidateDetails(candidateEmail, token)
        .then((data) => {
          dispatch(setCandidateDetails(data));
        })
        .catch((error) => {
          logout();
          navigate("/home");
        });
    }
  }, [user]);

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 4,
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        {candidateDetails && candidateDetails.length > 0 ? (
          candidateDetails.map((candidate) => (
            <Grid item key={candidate.company_id + candidate.position_id}>
              <CandidateCard
                candidate={candidate}
                onStartTest={handleStartTest}
              />
            </Grid>
          ))
        ) : (
          <Typography variant="h5" color="text.secondary">
            No tests available at the moment.
          </Typography>
        )}
      </Grid>

      <Dialog
        open={showTermsDialog}
        onClose={() => setShowTermsDialog(false)}
        sx={{ textAlign: "center" }}
      >
        <DialogContent>
          <TermsAndConditions />
          <DialogActions ref={termsContainerRef}>
            <CustomButton onClick={handleDeclineTerms} color="#191970">
              Decline
            </CustomButton>
            <CustomButton onClick={handleAcceptTerms} color="#6A5ACD">
              Accept
            </CustomButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default Dashboard;
