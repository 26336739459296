import React, { useState, useEffect } from "react";
import Confetti from "react-confetti";

import CustomNavbar from "../components/NavBar/CustomNavbar";

const SuccessPage = () => {
  const [confettiVisible, setConfettiVisible] = useState(true);

  useEffect(() => {
    const confettiTimer = setTimeout(() => {
      setConfettiVisible(false);
    }, 8000);

    return () => {
      clearTimeout(confettiTimer);
    };
  }, []);

  return (
    <div style={{ position: "relative", height: "100vh" }}>
      <CustomNavbar />
      {confettiVisible && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: "0%",
            transform: "translateX(-50%)",
          }}
        >
          <Confetti />
        </div>
      )}
      <div
        className="container mt-5"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <h2>Thank You</h2>
        {/* Add any additional success message or content here */}
      </div>
    </div>
  );
};

export default SuccessPage;
