import React, { useState } from "react";
import { uploadFile } from "../../api";
import { Button, Form } from "react-bootstrap";
import { Navigate } from "react-router-dom";

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [submitStatus, setSubmitStatus] = useState("");

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (
      selectedFile &&
      selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(selectedFile);
    } else {
      console.error("Invalid file type. Please select a .xlsx file.");
    }
  };

  const handleUpload = async () => {
    try {
      if (!file) {
        console.error("No file selected.");
        return;
      }
      // const formData = new FormData();
      // formData.append("answer_audio_path", audioBlob, "audio.mp3");

      const result = await uploadFile(file);
      setSubmitStatus("success");

      // Handle the result as needed
    } catch (error) {
      setSubmitStatus("failure");
      console.error("File Upload Error:", error);
    }
  };

  if (submitStatus === "success") {
    return <Navigate to="/success" />;
  }

  if (submitStatus === "failure") {
    return <Navigate to="/failure" />;
  }

  return (
    <div className="mt-3">
      <Form>
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Choose .xlsx file</Form.Label>
          <Form.Control
            type="file"
            accept=".xlsx"
            onChange={handleFileChange}
          />
          <Form.Text className="text-muted">
            Please select a file with the .xlsx extension.
          </Form.Text>
        </Form.Group>
        <Button variant="primary" onClick={handleUpload}>
          Upload
        </Button>
      </Form>
    </div>
  );
};

export default FileUpload;
