import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/js/dist/dropdown";
import "./navbarStyles.css";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Image } from "react-bootstrap";
// Modal
function CustomNavbar() {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    // Open the confirmation modal
    // Clear the token from the client side
    // Perform logout and redirect
    logout();
    navigate("/home");
  };

  return (
    <Navbar bg="light" expand="lg" style={{ padding: "10px 10%" }}>
      <Navbar.Brand
        as="span"
        style={{ color: "#6A5ACD", cursor: "default", display: "flex" }}
      >
        <Image
          src="./favicon2.ico" // Update the path to your logo image
          alt="Logo"
          height="25px"
          style={{ marginRight: "10px" }} // Adjust spacing as needed
        />
        <div>
          <span>
            <span style={{ color: "#191970", fontSize: "24px" }}>AI</span>
            NTERVIEW
          </span>
        </div>
        <div
          style={{
            borderRadius: "10px",

            padding: "4px",
            marginLeft: "5px",
            fontSize: "10px",
          }}
        >
          Beta
        </div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarNav" />
      <Navbar.Collapse id="navbarNav">
        <Nav className="ml-auto">
          {user ? (
            <NavDropdown title={user.fullName} id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => navigate("/dashboard")}>
                Home
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate("/profilepage")}>
                Your Profile
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
            </NavDropdown>
          ) : (
            <>
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
              {/* <NavDropdown title="Services" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/service1">
                  Service 1
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/service2">
                  Service 2
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/service3">
                  Service 3
                </NavDropdown.Item>
              </NavDropdown> */}
              <Nav.Link as={Link} to="/contact">
                Contact Us
              </Nav.Link>
              <Nav.Link as={Link} to="/about">
                About Us
              </Nav.Link>
              <Nav.Link as={Link} to="/signup">
                Sign Up
              </Nav.Link>
              <Nav.Link as={Link} to="/signin">
                Sign In
              </Nav.Link>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default CustomNavbar;
