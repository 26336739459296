// NotFound.js
import React from "react";
import "./NotFound.css"; // Import the CSS file

function NotFound() {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h1>404 Not Found</h1>
        <p>Sorry, the page you are looking for might be under construction.</p>
      </div>
    </div>
  );
}

export default NotFound;
