// CandidateCard.js
import React from "react";
import Typography from "@mui/material/Typography";
import CustomCard from "./CustomCard";
import CustomButton from "./CustomButton";

const CandidateCard = ({ candidate, onStartTest }) => {
  const expiryDate = new Date(candidate.expiry_date);
  expiryDate.setDate(expiryDate.getDate() - 1);
  expiryDate.setHours(23, 59, 0, 0);

  const currentDate = new Date();

  return (
    <CustomCard>
      <Typography
        variant="h6"
        style={{
          textAlign: "center",
          fontWeight: "bold",
          marginBottom: "20px",
        }}
        color="text.primary"
        gutterBottom>
        {candidate.company_name}
      </Typography>

      <Typography
        variant="body1"
        color="text.secondary"
        style={{ marginBottom: "15px" }}>
        <strong>Position:</strong> {candidate.position_name}
      </Typography>

      <Typography
        variant="body1"
        color="text.secondary"
        style={{ marginBottom: "15px" }}>
        <strong>Assessment Round:</strong> {candidate.test_level}
      </Typography>

      <Typography
        variant="body1"
        color="text.secondary"
        style={{ marginBottom: "15px" }}>
        <strong>Expiry Date:</strong>{" "}
        {expiryDate.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}
      </Typography>

      <CustomButton
        fullWidth
        onClick={onStartTest(
          candidate.company_id,
          candidate.position_id,
          candidate.test_key,
          candidate.candidate_id
        )}
        color="#6A5ACD"
        disabled={currentDate > expiryDate || candidate.test_status}>
        Take Test
      </CustomButton>
    </CustomCard>
  );
};

export default CandidateCard;
