// components/ForgotPassword.js
import React, { useState } from "react";
import axios from "axios";
import CustomCard from "../BaseComponents/CustomCard";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const handleForgotPassword = async () => {
    try {
      // Send a request to your backend to initiate the forgot password process
      const response = await axios.post(
        "https://core-api-jfm3errqea-el.a.run.app/api/users/forgot-password",
        {
          email,
        }
      );
      setMessage(response.data.message);
      navigate("/success");
    } catch (error) {
      console.error("Error in handleForgotPassword:", error);
      setMessage("An error occurred. Please try again later.");
      navigate("/failure");
    }
  };

  return (
    <div
      className="signin-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh", // Optional: Adjust to your preference
      }}>
      <CustomCard width="400px">
        <h2>Forgot Password</h2>
        <p>Enter your email address to receive a password reset link.</p>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel>Email:</InputLabel>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleForgotPassword}>
          Send Reset Link
        </Button>
        {message && <p>{message}</p>}
      </CustomCard>
    </div>
  );
};

export default ForgotPassword;
