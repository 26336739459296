import React from "react";
import Typography from "@mui/material/Typography";

const TermsAndConditions = () => {
  return (
    <div
      sx={{
        color: "black",
        textAlign: "left",
        margin: "auto",
        maxWidth: "800px",
        padding: 3, // Use theme.spacing(3) if using ThemeProvider
        backgroundColor: "#f9f9f9",
        borderRadius: 1, // Use theme.spacing(1) if using ThemeProvider
        boxShadow: 3, // Use theme.shadows[3] if using ThemeProvider
      }}
    >
      <Typography variant="h5">Terms and Conditions of AInterview</Typography>

      <Typography variant="body1" paragraph>
        <strong>Introduction:</strong>
        <br />
        The following terms and conditions outline the use of AI technology for
        candidate screening by AInterview.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>User Acceptance:</strong>
        <br />
        By using our AI-based candidate screening services, users agree to
        comply with these terms and conditions.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Candidate Consent:</strong>
        <br />
        Candidates participating in the screening process consent to the use of
        AI for questioning and validating answers by voice.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Data Collection and Storage:</strong>
        <br />
        AInterview collects and stores candidate responses and related data for
        the purpose of screening.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>AI-Based Screening Process:</strong>
        <br />
        The screening process involves AI-generated questions and voice-based
        answers, and candidates acknowledge and accept this methodology.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Privacy and Security:</strong>
        <br />
        AInterview is committed to safeguarding candidate data and follows
        industry-standard security measures to protect against unauthorized
        access.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Data Usage:</strong>
        <br />
        Candidate data collected during the screening process will be used
        solely for candidate evaluation purposes.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Third-Party Involvement:</strong>
        <br />
        AInterview may engage third-party service providers for AI technology or
        related services. These providers will be contractually bound to adhere
        to privacy and security standards.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Accuracy of AI:</strong>
        <br />
        While AInterview endeavors to ensure the accuracy of AI-based screening,
        it is not infallible. Candidates understand that inaccuracies may occur.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Equal Opportunity Employment:</strong>
        <br />
        AInterview is committed to providing equal employment opportunities and
        will not use AI-based screening to discriminate against candidates based
        on race, gender, ethnicity, or other protected characteristics.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Feedback and Appeals:</strong>
        <br />
        Candidates have the right to seek feedback on their screening results
        and may appeal decisions made by the AI-based system.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Changes to Terms:</strong>
        <br />
        AInterview reserves the right to modify these terms and conditions.
        Users will be notified of any changes.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Termination of Service:</strong>
        <br />
        AInterview reserves the right to terminate AI-based screening services
        at its discretion.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Legal Jurisdiction:</strong>
        <br />
        Any disputes arising from the use of AI-based screening services will be
        subject to the laws of Jurisdiction.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Contact Information:</strong>
        <br />
        For inquiries or concerns related to these terms, users may contact
        AInterview at{" "}
        <a href="mailto:ainterview.in@gmail.com">ainterview.in@gmail.com</a>.
      </Typography>

      <Typography variant="body1" paragraph>
        By using the AI-based screening services provided by AInterview, users
        acknowledge their acceptance of these terms and conditions.
      </Typography>
    </div>
  );
};

export default TermsAndConditions;
