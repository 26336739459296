// src/api.js
import axios from "axios";
import HandleApiError from "./HandleApiError";
// HandleApiError
// const API_BASE_URL = "http://localhost:3001/api"; // Update with the correct server URL
const API_BASE_URL = "https://core-api-jfm3errqea-el.a.run.app/api";
const api = axios.create({
  baseURL: API_BASE_URL,
});

export const createContact = async (contactData) => {
  try {
    const response = await api.post("/contact", contactData);
    return response.data;
  } catch (error) {
    // HandleApiError(error);
    throw error;
  }
};

export const signUp = async (signUnData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/users/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(signUnData),
    });

    if (!response.ok) {
      if (response.status === 400) {
        throw new Error(
          "Email already exists. Please choose a different email."
        );
      }
      // Handle network errors
      throw new Error("Network error occurred");
    }

    const data = await response.json();

    if (data.error) {
      // Handle server-side errors
      throw new Error(data.error);
    }

    return data;
  } catch (error) {
    // HandleApiError(error);
    throw error;
    // throw error;
  }
};

// New function for file upload
export const uploadFile = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file, "multipart/form-data");
    const response = await api.post("/upload/nimda", formData);

    return response.data;
  } catch (error) {
    // HandleApiError(error);
    throw error;
  }
};

export const submitQuestionResponse = async (QuestionResponse) => {
  try {
    const response = await api.post(
      "/submitquestionresponse",
      QuestionResponse
    );
    return response.data;
  } catch (error) {
    // HandleApiError(error);
    throw error;
  }
};
export const signIn = async (signInData) => {
  try {
    const response = await api.post("/users/signin", signInData);
    return response.data;
  } catch (error) {
    // HandleApiError(error);
    throw error;
  }
};

export const fetchCandidateDetails = async (candidateEmail, token) => {
  try {
    const response = await api.get(`/candidates/${candidateEmail}`, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    // HandleApiError(error);
    throw error;
  }
};

export const fetchCompanies = async () => {
  try {
    const response = await api.get("/companies");
    return response.data;
  } catch (error) {
    // HandleApiError(error);
    throw error;
  }
};

export const fetchPositionsByCompany = async (companyId) => {
  try {
    const response = await api.get(`/companies/${companyId}/positions`);
    return response.data;
  } catch (error) {
    // HandleApiError(error);
    throw error;
  }
};

export const submitQuestions = async (questionsData) => {
  try {
    const response = await api.post("/questions", questionsData);
    return response.data;
  } catch (error) {
    // HandleApiError(error);
    throw error;
  }
};

export const fetchTestDetails = async (
  company_id,
  position_id,
  test_key,
  token
) => {
  try {
    const response = await api.get(
      `/questioncount/${company_id}/${position_id}/${test_key}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    // HandleApiError(error);
    throw error;
  }
};

export const fetchQuestions = async (
  company_id,
  position_id,
  test_key,
  token
) => {
  try {
    const response = await api.get(
      `/testdetails/${company_id}/${position_id}/${test_key}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    // HandleApiError(error);
    throw error;
  }
};

export const updateTestStatus = async (
  candidateEmail,
  testStatus,
  position_id,
  token
) => {
  try {
    const response = await api.put(
      `/candidates/${candidateEmail}/${position_id}/update-test-status`,
      { testStatus },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    // HandleApiError(error);
    throw error;
  }
};

export const getTestStatus = async (candidateEmail, position_id, token) => {
  try {
    const response = await api.get(
      `/candidates/${candidateEmail}/${position_id}/get-test-status`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    // HandleApiError(error);
    throw error;
  }
};
// fetchQuestionData

export const endTest = async (candidate_id, questions, test_key, token) => {
  try {
    const response = await api.post(
      `/endTest`,
      {
        candidate_id,
        questions,
        test_key,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    // HandleApiError(error);
    throw error;
  }
};
