import React from "react";
import "./AboutUs.css"; // Import your CSS file
import Surya from "../../assets/Surya.jpg";
import Navin from "../../assets/Navin.jpg";

const AboutUsPage = () => {
  return (
    <div className="about-us-container">
      {/* Top Image */}

      <h2
        className="team-heading"
        style={{ textAlign: "center", marginTop: "50px" }}
      >
        Meet the team behind AInterview
      </h2>

      {/* Team Member 1 */}
      <div className="team-member">
        <img src={Surya} alt="Team Member 1" className="team-member-image" />
        <div className="team-member-details">
          <h3>M Surya pavan</h3>
          <p>Founder & CEO</p>
          <p style={{ textAlign: "Left", marginTop: "5px" }}>
            M Surya Pavan is the Founder and CEO of AInterview. With a passion
            for innovation, he leads the team in revolutionizing the candidate
            screening process.
          </p>
        </div>
      </div>

      {/* Team Member 2 */}
      <div className="team-member">
        <img src={Navin} alt="Team Member 2" className="team-member-image" />
        <div className="team-member-details">
          <h3>S Naveen</h3>
          <p>Co-Founder & CTO </p>
          <p style={{ textAlign: "Left", marginTop: "5px" }}>
            S Naveen, our CTO, and lead developer, brings technical expertise to
            the team. His dedication to excellence ensures the success of our
            AI-driven platform.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
