import React from "react";

import SignIn from "../components/Auth/SignIn";
import CustomNavbar from "../components/NavBar/CustomNavbar";

function ContactUsPage() {
  return (
    <div className="App">
      <CustomNavbar />
      <SignIn />
    </div>
  );
}

export default ContactUsPage;
