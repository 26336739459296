import React from "react";

// import SignIn from '../components/SignIn';
import Dashboard from "../components/DashBoard/DashBoard";
import CustomNavbar from "../components/NavBar/CustomNavbar";

function DashBoardPage() {
  return (
    <div className="App">
      <CustomNavbar />
      <Dashboard />
    </div>
  );
}

export default DashBoardPage;
