import React, { useState } from "react";
import { createContact } from "../../api";
import { Navigate, useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CustomCard from "../BaseComponents/CustomCard";
import img from "../../assets/contact_us (1).jpg";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    companyName: "",
    phoneNumber: "",
    email: "",
    industry: "",
    message: "",
  });
  const [submitStatus, setSubmitStatus] = useState(""); // 'success', 'failure', or ''
  const [validPhone, setValidPhone] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const regex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
    const valid = regex.test(formData.phoneNumber);

    if (!valid) {
      setValidPhone(false);
      setPhoneError("Please enter a 10-digit valid phone number.");
      return;
    } else {
      try {
        const newContact = await createContact(formData);
        setSubmitStatus("success");
      } catch (error) {
        setSubmitStatus("failure");
        // Log or display the error message
        console.error("Error registering user:", error.message);
      }
    }
  };

  if (submitStatus === "success") {
    navigate("/success");
  }

  if (submitStatus === "failure") {
    navigate("/failure");
  }

  const phoneInputStyle = {
    width: "100%", // Adjust the width as needed
  };

  return (
    <div style={{ display: "flex" }}>
      <img
        src={img}
        alt="home"
        style={{
          marginTop: "3%",
          marginLeft: "10%",
          width: "43%",
        }}
      />
      <CustomCard
        customStyles={{ marginTop: "3%", width: "40%", borderRadius: "0%" }}
      >
        <div>
          <div
            style={{
              alignContent: "center",
              marginBottom: "30px",
            }}
          >
            <h2>Contact Us</h2>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="companyName">Company Name:</label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phoneNumber">Phone Number:</label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <PhoneInput
                  onlyCountries={["in"]}
                  showDropdown={true}
                  value={formData.phoneNumber}
                  onChange={(value) =>
                    setFormData({ ...formData, phoneNumber: value })
                  }
                />
              </div>
              {phoneError && <div className="text-danger">{phoneError}</div>}
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="industry">Industry:</label>
              <input
                type="text"
                className="form-control"
                id="industry"
                name="industry"
                value={formData.industry}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                className="form-control"
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </CustomCard>
    </div>
  );
};

export default ContactUs;
