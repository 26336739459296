import React, { useState } from "react";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";
import flags from "react-phone-number-input/flags";
import "./SignUp.css";
import { signUp } from "../../api";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CustomCard from "../BaseComponents/CustomCard";
import img from "../../assets/5423351_Mobile login.svg";

const SignUp = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    occupation: "",
    institution: "",
    password: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();
  const [passwordError, setPasswordError] = useState("");
  const [ageError, setAgeError] = useState("");
  const [submitStatus, setSubmitStatus] = useState(""); // 'success', 'failure', or ''
  const [failureMessage, setFailureMessage] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const phoneInputStyle = {
    width: "100%", // Adjust the width as needed
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const validPhone = (phone) => {
    console.log({ phone });
    const regex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
    const valid = regex.test(phone);
    return valid;
  };
  const handlePasswordChange = (e) => {
    const password = e.target.value;
    const isStrongPassword = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/.test(
      password
    );

    setFormData({ ...formData, password });

    if (isStrongPassword || password === "") {
      setPasswordError("");
    } else {
      setPasswordError(
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one digit."
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for strong password before submission
    if (passwordError) {
      alert("Please fix the password issues before submitting the form.");
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setConfirmPasswordError(
        "Passwords do not match. Please make sure they match before submitting the form."
      );
      return;
    }

    // Check age
    const age = calculateAge(formData.dateOfBirth);
    if (age < 18) {
      setAgeError("Sorry, You must be at least 18 years old to sign up.");
      return;
    }
    // Call the API to register the user

    const validPhn = validPhone(formData.phoneNumber);
    if (!validPhn) {
      setPhoneError("Please enter a 10-digit valid phone number.");
      return;
    }
    try {
      const newUser = await signUp(formData);
      setSubmitStatus("success");
    } catch (error) {
      setSubmitStatus("failure");
      setFailureMessage(error.message); // Set the specific error message
      console.error("Error registering user:", error.message);
    }
  };

  if (submitStatus === "success") {
    navigate("/success");
  }

  return (
    <div style={{ display: "flex" }}>
      <img
        src={img}
        alt="home"
        style={{
          marginTop: "3%",
          marginLeft: "10%",
          width: "43%",
          borderRadius: "0%",
        }}
      />
      <CustomCard
        customStyles={{ marginTop: "3%", width: "40%", borderRadius: "0%" }}
      >
        <div>
          <div
            style={{
              alignContent: "center",
              marginBottom: "30px",
            }}
          >
            <h2>Sign Up</h2>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="fullName">Full Name:</label>
              <input
                type="text"
                className="form-control"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phoneNumber">Phone Number:</label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <PhoneInput
                  onlyCountries={["in"]}
                  showDropdown={true}
                  value={formData.phoneNumber}
                  onChange={(value) =>
                    setFormData({ ...formData, phoneNumber: value })
                  }
                />
              </div>
              {phoneError && <div className="text-danger">{phoneError}</div>}
            </div>
            <div className="form-group">
              <label htmlFor="dateOfBirth">Date of Birth:</label>
              <input
                type="date"
                className="form-control"
                id="dateOfBirth"
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={handleChange}
                required
              />
              {ageError && <div className="text-danger">{ageError}</div>}
            </div>
            <div className="form-group">
              <label htmlFor="occupation">Occupation:</label>
              <select
                className="form-control"
                id="occupation"
                name="occupation"
                value={formData.occupation}
                onChange={handleChange}
                required
              >
                <option value="">Select</option>
                <option value="student">Student</option>
                <option value="professional">Working Professional</option>
              </select>
            </div>
            {formData.occupation === "student" && (
              <div className="form-group">
                <label htmlFor="institution">College/Institution:</label>
                <input
                  type="text"
                  className="form-control"
                  id="institution"
                  name="institution"
                  value={formData.institution}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
            {formData.occupation === "professional" && (
              <div className="form-group">
                <label htmlFor="company">Company:</label>
                <input
                  type="text"
                  className="form-control"
                  id="company"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                value={formData.password}
                onChange={handlePasswordChange}
                required
              />
            </div>
            {passwordError && (
              <div className="text-danger">{passwordError}</div>
            )}
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm Password:</label>
              <input
                type="password"
                className="form-control"
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
            </div>
            {confirmPasswordError && (
              <div className="text-danger">{confirmPasswordError}</div>
            )}

            {submitStatus === "failure" && (
              <p className="text-danger">{failureMessage}</p>
            )}
            <button type="submit" className="btn btn-primary">
              Sign Up
            </button>
          </form>
        </div>
      </CustomCard>
    </div>
  );
};

export default SignUp;
