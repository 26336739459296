import React from "react";

import SignUp from "../components/Auth/SignUp";
import CustomNavbar from "../components/NavBar/CustomNavbar";

function ContactUsPage() {
  return (
    <div className="App">
      <CustomNavbar />
      <SignUp />
    </div>
  );
}

export default ContactUsPage;
