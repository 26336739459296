import React from "react";
import CustomNavbar from "../components/NavBar/CustomNavbar";
import TestDetails from "../components/Test/TestDetails";

function TestDetailsPage() {
  return (
    <div className="App">
      <CustomNavbar />
      <TestDetails />
    </div>
  );
}

export default TestDetailsPage;
