import React from "react";

import Home from "../components/Home";
import CustomNavbar from "../components/NavBar/CustomNavbar";
import QuestionPage from "../components/Questions/QuestionsUpload";

function HomePage() {
  return (
    <div className="App">
      <CustomNavbar />
      <QuestionPage />
    </div>
  );
}

export default HomePage;
