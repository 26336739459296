import { combineReducers } from "@reduxjs/toolkit";
import dashboardReducer from "./dashboardReducer";
import testDetailsReducer from "./testDetailsReducer";

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  testDetails: testDetailsReducer,
});

export default rootReducer;
