import React from "react";
import AboutUs from "../components/AboutUs/AboutUs";
import CustomNavbar from "../components/NavBar/CustomNavbar";

function AboutUsPage() {
  return (
    <div className="App">
      <CustomNavbar />
      <AboutUs />
    </div>
  );
}

export default AboutUsPage;
