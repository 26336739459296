import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  fetchCompanies,
  fetchPositionsByCompany,
  submitQuestions,
} from "../../api";
import { Navigate } from "react-router-dom";
import CustomButton from "../BaseComponents/CustomButton";

const QuestionPage = () => {
  const [companies, setCompanies] = useState([]);
  const [positions, setPositions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [questions, setQuestions] = useState([{ text: "" }]);
  const [testKey, setTestKey] = useState(""); // Added test key state
  const [submitStatus, setSubmitStatus] = useState("");

  useEffect(() => {
    fetchCompanies()
      .then((data) => setCompanies(data))
      .catch((error) => console.error("Error fetching companies:", error));
  }, []);

  const handleCompanyChange = async (companyId) => {
    setSelectedCompany(companyId);

    try {
      const data = await fetchPositionsByCompany(companyId);
      setPositions(data);
    } catch (error) {
      console.error("Error fetching positions:", error);
    }
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, { text: "" }]);
  };

  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].text = value;
    setQuestions(updatedQuestions);
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = [...questions];
    if (updatedQuestions.length > 1) {
      updatedQuestions.splice(index, 1);
    } else {
      alert("At least one question needs to be provided.");
    }

    setQuestions(updatedQuestions);
  };

  const handleSubmit = async () => {
    try {
      if (!selectedCompany || !selectedPosition) {
        console.error("Company and Position must be selected.");
        return;
      }

      const questionTexts = questions
        .map((question) => question.text.trim())
        .filter(Boolean);

      if (questionTexts.length === 0) {
        console.error("At least one question must be provided.");
        return;
      }

      if (!testKey.trim()) {
        console.error("Test key must be provided.");
        return;
      }

      const requestData = {
        companyId: selectedCompany,
        positionId: selectedPosition,
        questions: questionTexts,
        testKey: testKey.trim(),
      };
      const response = await submitQuestions(requestData);
      setSubmitStatus("success");
    } catch (error) {
      console.error("Error submitting questions:", error);
      setSubmitStatus("failure");
    }
  };

  if (submitStatus === "success") {
    return <Navigate to="/success" />;
  }

  if (submitStatus === "failure") {
    return <Navigate to="/failure" />;
  }

  return (
    <Container>
      <Typography variant="h4" sx={{ mt: 3, mb: 2 }}>
        Company Positions and Questions
      </Typography>

      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="company-label">Select Company</InputLabel>
        <Select
          labelId="company-label"
          id="company"
          value={selectedCompany}
          label="Select Company"
          onChange={(e) => handleCompanyChange(e.target.value)}>
          {companies.map((company) => (
            <MenuItem key={company.company_id} value={company.company_id}>
              {company.company_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="position-label">Select Position</InputLabel>
        <Select
          labelId="position-label"
          id="position"
          value={selectedPosition}
          label="Select Position"
          onChange={(e) => setSelectedPosition(e.target.value)}>
          {positions.map((position) => (
            <MenuItem key={position.position_id} value={position.position_id}>
              {position.position_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        label="Test Key"
        variant="outlined"
        fullWidth
        value={testKey}
        onChange={(e) => setTestKey(e.target.value)}
        sx={{ mt: 2 }}
      />

      <Typography variant="h5" sx={{ mt: 3 }}>
        Add Questions:
      </Typography>
      {questions.map((question, index) => (
        <Grid container spacing={2} key={index}>
          <Grid item xs={10}>
            <TextField
              label={`Question ${index + 1}`}
              variant="outlined"
              fullWidth
              multiline
              value={question.text}
              onChange={(e) => handleQuestionChange(index, e.target.value)}
              sx={{ mt: 2 }}
            />
          </Grid>
          <Grid item xs={2}>
            <CustomButton
              color="#191970"
              onClick={() => handleRemoveQuestion(index)}>
              <RemoveIcon />
            </CustomButton>
          </Grid>
        </Grid>
      ))}

      <Grid container spacing={2} justifyContent="space-between">
        <Grid item>
          <CustomButton color="#6A5ACD" onClick={handleAddQuestion}>
            <AddIcon />
          </CustomButton>
        </Grid>
        <Grid item>
          <CustomButton
            onClick={handleSubmit}
            color="#6A5ACD"
            disabled={
              !selectedCompany ||
              !selectedPosition ||
              questions.some((q) => !q.text.trim()) ||
              !testKey.trim()
            }>
            Submit
          </CustomButton>
        </Grid>
      </Grid>
    </Container>
  );
};

export default QuestionPage;

// {questions.map((question, index) => (
//   <Grid container spacing={2} key={index}>
//     <Grid item xs={10}>
//       <TextField
//         label={`Question ${index + 1}`}
//         variant="outlined"
//         fullWidth
//         multiline
//         value={question.text}
//         onChange={(e) => handleQuestionChange(index, e.target.value)}
//         sx={{ mt: 2 }}
//       />
//     </Grid>
//     <Grid item xs={2}>
//       <CustomButton
//         onClick={() => handleRemoveQuestion(index)}
//       >
//         <RemoveIcon />
//       </CustomButton>
//     </Grid>
//   </Grid>
// ))}

// <Grid container spacing={2} justifyContent="flex-end"> {/* Add this line */}
//   <CustomButton onClick={handleAddQuestion}>
//     <AddIcon />
//   </CustomButton>

//   <CustomButton
//     onClick={handleSubmit}
//     color="#FF0000"
//     disabled={
//       !selectedCompany ||
//       !selectedPosition ||
//       questions.some((q) => !q.text.trim()) ||
//       !testKey.trim()
//     }
//   >
//     Submit
//   </CustomButton>
// </Grid>
