import React, { useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import CustomCard from "../BaseComponents/CustomCard";
import axios from "axios";

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const email = queryParams.get("email");

  console.log({ token, email });

  const navigate = useNavigate();
  const [resetStatus, setResetStatus] = useState("");
  const [resetMessage, setResetMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if password and confirm password match
    if (formData.password !== formData.confirmPassword) {
      setResetStatus("failure");
      setResetMessage("Password and Confirm Password do not match.");
      return;
    }
    try {
      // Send a request to your backend to initiate the forgot password process
      const response = await axios.post(
        "https://core-api-jfm3errqea-el.a.run.app/api/users/reset-password",
        {
          email,
          newPassword: formData.password,
          token,
        }
      );
      setResetMessage(response.data.message);
    } catch (error) {
      console.error("Error in handleForgotPassword:", error);
      setResetMessage("An error occurred. Please try again later.");
      navigate("/failure");
    }

    // Implement your reset password logic here
    // You can use an API call similar to the sign-in example

    // For demonstration purposes, let's assume the reset was successful
    setResetStatus("success");
    setResetMessage("Password reset successful!");
    navigate("/success");
  };

  return (
    <div
      className="reset-password-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh", // Optional: Adjust to your preference
      }}
    >
      <CustomCard title="Reset Password" width="600px">
        <h2>Reset Password</h2>
        {resetStatus === "success" ? (
          <p className="text-success">{resetMessage}</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <div
              className="form-group"
              style={{ alignContent: "center", marginBottom: "15px" }}
            >
              <label htmlFor="email" className="form-label">
                Email:
                <span style={{ marginLeft: "10px" }}>{email}</span>
              </label>
            </div>
            <div
              className="form-group"
              style={{ display: "flex", marginBottom: "15px" }}
            >
              <label htmlFor="password" className="form-label">
                New Password:
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                style={{ width: "300px", marginLeft: "85px" }}
              />
            </div>
            <div
              className="form-group"
              style={{ display: "flex", marginBottom: "15px" }}
            >
              <label htmlFor="confirmPassword" className="form-label">
                Confirm Password:
              </label>
              <input
                type="password"
                className="form-control"
                id="confirmPassword"
                name="confirmPassword"
                style={{ width: "300px", marginLeft: "60px" }}
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
            </div>
            {resetStatus === "failure" && (
              <p className="text-danger mt-2">{resetMessage}</p>
            )}
            <button type="submit" className="btn btn-primary btn-block">
              Reset Password
            </button>
            <div className="mt-3">
              <Link to="/signin">
                <button className="btn btn-link">Back to Sign In</button>
              </Link>
            </div>
          </form>
        )}
      </CustomCard>
    </div>
  );
};

export default ResetPassword;
