import React, { useEffect, useRef, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import CustomCard from "../BaseComponents/CustomCard";
import CustomButton from "../BaseComponents/CustomButton";
import CountdownTimer from "../BaseComponents/CountdownTimer";
import { useAudioRecorder } from "react-audio-voice-recorder";
import {
  fetchQuestions,
  submitQuestionResponse,
  updateTestStatus,
  getTestStatus,
  endTest,
} from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import RecordingPopup from "../BaseComponents/RecordingPopup";
import { useSelector } from "react-redux";
//restore the old code

const TestPage = () => {
  const { startRecording, stopRecording, recordingBlob, isRecording } =
    useAudioRecorder();
  const token = localStorage.getItem("token");
  const audioElementRef = useRef(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [questions, setQuestions] = useState([]);
  const payload = useSelector((state) => state.dashboard.candidateDetails);
  const { company_id, position_id, test_key, candidate_id } = useSelector(
    (state) => state.dashboard.selectedTestDetails
  );
  const navigate = useNavigate();
  const [timerKey, setTimerKey] = useState(0);
  const [showAnswerTimer, setShowAnswerTimer] = useState(false);
  const [readingTimeDuration, setReadingTimeDuration] = useState(45);
  const [answeringTimeDuration, setAnsweringTimeDuration] = useState(45);
  const [readingColors, setReadingColors] = useState(["#4CAF50"]);
  const [answeringColors, setAnsweringColors] = useState(["#A30000"]);
  const [recordedBlob, setRecordedBlob] = useState(null);
  const [showRecordingPopup, setShowRecordingPopup] = useState(false);
  const [question, setQuestion] = useState({});
  const [endTestClicked, setEndTestClicked] = useState(false);
  useEffect(() => {
    const checkTestStatus = async () => {
      try {
        const userData = JSON.parse(localStorage.getItem("user"));
        const candidateEmail = userData ? userData.email : null;

        const { testStatus } = await getTestStatus(
          candidateEmail,
          position_id,
          token
        );
        if (testStatus) {
          stopRecording();
          navigate("/dashboard");
        }
      } catch (error) {
        console.error("Error checking test status:", error);
      }
    };

    checkTestStatus();
  }, [navigate, position_id]);

  useEffect(() => {
    if (stopRecording) {
      setRecordedBlob(recordingBlob);
      sendAudioToBackend(recordingBlob);
    }
  }, [recordingBlob, stopRecording]);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const data = await fetchQuestions(
          company_id,
          position_id,
          test_key,
          token
        );
        setQuestions(data);
      } catch (error) {
        console.error("Error fetching test details:", error);
      }
    };

    fetchDetails();
  }, [company_id, position_id, test_key]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!endTestClicked) {
        alert(
          "You are trying to exit fullscreen. Your test will end abruptly."
        );
      }
      handleEndTest();
      navigate("/dashboard");
    };

    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        if (!endTestClicked) {
          alert(
            "You are trying to exit fullscreen. Your test will end abruptly."
          );
        }
        handleEndTest();
        navigate("/dashboard");
      }
    };
    const handleUnload = () => {
      if (!endTestClicked) {
        alert(
          "You are trying to exit fullscreen. Your test will end abruptly."
        );
      }
      handleEndTest();
      navigate("/dashboard");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    // window.addEventListener("unload", handleUnload);
    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      // window.removeEventListener("unload", handleUnload);
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const sendAudioToBackend = async (answer_audio_path) => {
    try {
      const question_id = question.question_id;
      const formData = new FormData();
      const audioBlob = new Blob([answer_audio_path], { type: "audio/mp3" });
      formData.append("answer_audio_path", audioBlob, "audio.mp3");
      formData.append("candidate_id", candidate_id);
      formData.append("question_id", question_id);
      formData.append("test_key", test_key);
      const response = await submitQuestionResponse(formData);
    } catch (error) {
      console.error("Error uploading audio:", error);
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestion((prevQuestion) => prevQuestion + 1);
    setTimerKey((prevKey) => prevKey + 2);
    setShowAnswerTimer(false);
  };

  const handleEndTest = async () => {
    try {
      const userData = JSON.parse(localStorage.getItem("user"));
      const candidateEmail = userData ? userData.email : null;

      await Promise.all([
        updateTestStatus(candidateEmail, true, position_id, token),
        getAnsweredQuestionsData(),
      ]);

      if (document.fullscreenElement) {
        document.exitFullscreen();
      }

      stopRecording();
      setEndTestClicked(true);
      navigate("/dashboard");
      window.location.reload();
    } catch (error) {
      stopRecording();
      console.error("Error updating test status:", error);
    }
  };

  const handleTimerComplete = (questionData) => {
    setQuestion(questionData);

    if (!showAnswerTimer) {
      setShowAnswerTimer(true);
      setShowRecordingPopup(true);
      startRecording();
      setTimeout(() => {
        setShowRecordingPopup(false);
      }, 5000); // Show the popup for 5 seconds before starting recording
    } else {
      stopRecording();
      sendAudioToBackend(recordingBlob); // Call sendAudioToBackend when answering time is completed
      handleNextQuestion();
    }
  };
  const getAnsweredQuestionsData = async () => {
    try {
      endTest(candidate_id, questions, test_key, token);
    } catch (error) {
      console.error("Error fetching answered questions data:", error);
    }
  };
  const currentQuestionData = useMemo(() => {
    return questions[currentQuestion] || {};
  }, [currentQuestion, questions]);

  return (
    <Container
      style={{
        backgroundImage: "../../assets/PXL_20231217_083837261~2.jpg",
        backgroundSize: "cover",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CustomCard width="500px" height="300px">
        {currentQuestionData.question_text ? (
          <>
            <Typography
              variant="h5"
              gutterBottom
              style={{ textAlign: "center" }}
            >
              {currentQuestion + 1}. {currentQuestionData.question_text}
              <div style={{ color: "#191970" }}>
                {showAnswerTimer && showRecordingPopup && isRecording && (
                  <RecordingPopup
                    message={
                      <div>
                        Recording has started, Please start answering the
                        question
                      </div>
                    }
                    onClose={() => setShowRecordingPopup(false)}
                  />
                )}
              </div>{" "}
              {/* <div className="mb-3">
                <audio
                  ref={audioElementRef}
                  controls
                  src={recordedBlob && URL.createObjectURL(recordedBlob)}
                />
              </div> */}
              <div
                style={{
                  position: "relative",
                  marginBottom: "20px",
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                }}
              >
                {!showAnswerTimer ? (
                  <CountdownTimer
                    isPlaying={!showAnswerTimer}
                    duration={readingTimeDuration}
                    size={150}
                    strokeWidth={15}
                    colors={readingColors}
                    onComplete={() => handleTimerComplete(currentQuestionData)}
                    pkey={timerKey}
                    text="Reading Time"
                  />
                ) : (
                  <CountdownTimer
                    isPlaying={showAnswerTimer}
                    duration={answeringTimeDuration}
                    size={150}
                    strokeWidth={15}
                    colors={answeringColors}
                    onComplete={() => handleTimerComplete(currentQuestionData)}
                    pkey={timerKey + 1}
                    text="Answering Time"
                  />
                )}
              </div>
            </Typography>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <CustomButton
                variant="contained"
                onClick={() => {
                  stopRecording();
                  handleEndTest();
                }}
                color="#191970"
              >
                End Test
              </CustomButton>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {showAnswerTimer ? (
                  <span style={{ color: "red", fontSize: "32px" }}>
                    <FontAwesomeIcon icon={faMicrophone} />
                  </span>
                ) : (
                  <span style={{ color: "black", fontSize: "32px" }}>
                    ≠ <FontAwesomeIcon icon={faMicrophone} />
                  </span>
                )}
              </div>
              <CustomButton
                variant="contained"
                onClick={() => {
                  sendAudioToBackend(recordingBlob); // Call sendAudioToBackend when "Next" button is clicked
                  handleTimerComplete(currentQuestionData);
                }}
                color="#6A5ACD"
                disabled={!showAnswerTimer}
              >
                Next
              </CustomButton>
            </div>
          </>
        ) : (
          <>
            <Typography
              variant="h6"
              gutterBottom
              style={{ textAlign: "center" }}
            >
              Assessment Completed, Good luck ahead !!
            </Typography>
            <CustomButton
              variant="contained"
              onClick={handleEndTest}
              color="#191970"
            >
              End Test
            </CustomButton>
          </>
        )}
      </CustomCard>
    </Container>
  );
};

export default TestPage;
