import React from "react";
import CustomNavbar from "../components/NavBar/CustomNavbar";
import ProfilePage from "../components/ProfilePage/ProfilePage";

function HomePage() {
  return (
    <div className="App">
      <CustomNavbar />
      <ProfilePage />
    </div>
  );
}

export default HomePage;
