import React from 'react';
import CustomNavbar from '../components/NavBar/CustomNavbar';
import FileUpload  from '../components/FileUpload/FileUpload';

function FileUploadPage(){
  return (
    <div className="App">
      <CustomNavbar/>
      <FileUpload />
    </div>
  );
}

export default FileUploadPage;
