import React from "react";

import CustomNavbar from "../components/NavBar/CustomNavbar";

const FailurePage = ({ failureMessage }) => {
  return (
    <div>
      <CustomNavbar />
      <div
        className="container mt-5"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <h2>Form Submission Failed!</h2>
        {failureMessage && <p>{failureMessage}</p>}
        {/* Add any additional failure message or content here */}
      </div>
    </div>
  );
};

export default FailurePage;
