import React from "react";
import { Link, Element, animateScroll as scroll } from "react-scroll";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import "./HomePage.css"; // Import the CSS file
import "animate.css/animate.min.css"; // Import Animate.css
import firsec from "../assets/Home_Page_Sec_1.png";
import secondsec from "../assets/Home_Page_Sec_2.png";
const HomePage = () => {
  const handleSetActive = (to) => {
    const element = document.getElementById(to);
    if (element) {
      element.classList.add("active");
    }
  };

  const handleSetStatus = (to, status) => {
    if (status === "INACTIVE") {
      const element = document.getElementById(to);
      if (element) {
        element.classList.remove("active");
      }
    }
  };

  return (
    <div>
      {/* Section 1: Introduction */}
      <Element name="section1" className="element" id="section1">
        <div className="section-container">
          <div className="column">
            <img src={firsec} alt="Company Logo" className="company-logo" />
          </div>
          <div className="column">
            <h1>Welcome to AInterview </h1>
            <p style={{ textAlign: "justify", marginTop: "50px" }}>
              your trusted partner in revolutionizing the candidate screening
              process! Leveraging cutting-edge artificial intelligence, we bring
              you a seamless and efficient solution to screen candidates for
              your specified positions without the need for human intervention.
            </p>
          </div>
        </div>
      </Element>
      {/* Section 2: Introduction */}
      <Element name="section1" className="element" id="section1">
        <div className="two-column-container">
          <div className="column">
            <h1>Our Innovative Approach </h1>
            <p
              style={{
                textAlign: "justify",
                marginTop: "50px",
                marginBottom: "50px",
              }}
            >
              At AInterview, we have redefined the screening process, making it
              faster, unbiased, and remarkably accurate. Through the power of
              artificial intelligence, candidates undergo a comprehensive test
              tailored to your company's requirements, answering questions that
              allow us to evaluate their skills and suitability for the
              designated position.
            </p>
          </div>
          <div className="column">
            <img
              src={secondsec}
              alt="Innovative Approach Image"
              className="innovative-image"
            />
          </div>
        </div>
      </Element>

      {/* Section 3: Key Features */}
      <Element
        name="section2"
        className="element"
        id="section2"
        onSetActive={() => handleSetActive("section2")}
        onSetStatus={(to, status) => handleSetStatus("section2", status)}
      >
        <h2>Key Features</h2>
        <div
          className="key-features-container"
          style={{ textAlign: "justify", marginTop: "50px" }}
        >
          <div className="key-feature">
            <h3>Automation at Its Best</h3>
            <p style={{ textAlign: "Left" }}>
              Our AI-driven platform automates the entire screening process,
              eliminating the need for manual intervention. This not only saves
              time but also ensures consistent and fair evaluations for every
              candidate.
            </p>
          </div>
          <div className="key-feature">
            <h3>Unbiased Evaluation</h3>
            <p style={{ textAlign: "Left" }}>
              Say goodbye to biases in the screening process. Our AI system
              evaluates candidates solely based on their responses, promoting a
              fair and unbiased assessment of skills and qualifications.
            </p>
          </div>
          <div className="key-feature">
            <h3>User-Friendly Interface</h3>
            <p style={{ textAlign: "Left" }}>
              We understand the importance of a positive candidate experience.
              Our platform boasts a user-friendly interface, ensuring that
              candidates can easily navigate through the testing process,
              creating a seamless and enjoyable experience.
            </p>
          </div>
          <div className="key-feature">
            <h3>Cost-Efficient Solutions</h3>
            <p style={{ textAlign: "Left" }}>
              By automating the screening process, we significantly reduce the
              resources and time traditionally spent on manual evaluations. This
              translates into substantial cost savings for your company, making
              the screening process both efficient and economical.
            </p>
          </div>
        </div>
      </Element>

      {/* Section 4: Testimonials/Reviews */}
      {/* Section 4: Testimonials/Reviews */}
      {/* Section 4: Testimonials/Reviews */}
      <Element
        name="section3"
        className="element"
        id="section3"
        onSetActive={() => handleSetActive("section3")}
        onSetStatus={(to, status) => handleSetStatus("section3", status)}
      >
        <h2 className="animate__animated animate__fadeIn">How It Works:</h2>
        <div className="testimonial">
          <p className="animate__animated animate__fadeIn">
            <strong>Customized Tests:</strong> Tailor the screening tests to
            match the specific requirements of your company and the desired
            position.
          </p>

          <p className="animate__animated animate__fadeIn">
            <strong>Candidate Engagement:</strong> Candidates take the tests
            through our intuitive platform, providing responses that reflect
            their skills and qualifications.
          </p>
          <p className="animate__animated animate__fadeIn">
            <strong>AI Validation:</strong> Behind the scenes, our powerful AI
            algorithms analyze and validate each response, ensuring accuracy and
            reliability.
          </p>
          <p className="animate__animated animate__fadeIn">
            <strong>Comprehensive Analysis Report:</strong> Receive a detailed
            analysis report for each candidate, highlighting their strengths and
            areas for improvement. This report empowers your hiring team to make
            informed decisions quickly.
          </p>
          <p className="animate__animated animate__fadeIn">
            Join the future of candidate screening with{" "}
            <strong>AInterview</strong>. Experience efficiency, objectivity, and
            cost savings like never before. Transform your hiring process today!
          </p>
        </div>
      </Element>

      <Element
        name="section5"
        className="element"
        id="section5"
        onSetActive={() => handleSetActive("section5")}
        onSetStatus={(to, status) => handleSetStatus("section5", status)}
      >
        <h2>Contact Us</h2>
        <p>
          Have questions or want to learn more? Contact us through the
          information below.
        </p>
        {/* <div className="contact-info">
          <p>Email: info@example.com</p>
          <p>Phone: +1 (123) 456-7890</p> 
        </div> */}
      </Element>
      {/* Footer: Social Links and Company Information */}
      <footer>
        <div className="social-icons">
          <Link to="section1" smooth={true} duration={500}>
            <FaFacebook />
          </Link>
          <Link to="section2" smooth={true} duration={500}>
            <FaTwitter />
          </Link>
          <Link to="section3" smooth={true} duration={500}>
            <FaInstagram />
          </Link>
          <Link to="section4" smooth={true} duration={500}>
            <FaLinkedin />
          </Link>
        </div>
        <div className="company-info">
          <p>&copy; 2024 Your Company. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};
export default HomePage;
