import React from "react";
import CustomNavbar from "../components/NavBar/CustomNavbar";
import NotFound1 from "../components/NotFound/NotFound";
function NotFound() {
  return (
    <div className="App">
      <CustomNavbar />
      <NotFound1 />
    </div>
  );
}

export default NotFound;
