import React from "react";

import SignIn from "../components/Auth/SignIn";
import CustomNavbar from "../components/NavBar/CustomNavbar";
import ForgotPassword from "../components/Auth/ForgotPassword";

function ContactUsPage() {
  return (
    <div className="App">
      <CustomNavbar />
      <ForgotPassword />
    </div>
  );
}

export default ContactUsPage;
