import React, { useState } from "react";
import { useParams } from "react-router";

const AudioPlayer = () => {
  const [audioBuffer, setAudioBuffer] = useState(null);
  const [audioUrl, setAudioUrl] = useState("");
  const { id } = useParams();
  const audioId = id;

  const loadAudio = async () => {
    try {
      const response = await fetch(
        `https://core-api-jfm3errqea-el.a.run.app/api/upload/audio/${audioId}`
      );
      const arrayBuffer = await response.arrayBuffer();
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const buffer = await audioContext.decodeAudioData(arrayBuffer);
      setAudioBuffer(buffer);
    } catch (error) {
      console.error(error);
    }
  };
  const playAudio = async () => {
    if (!audioBuffer) {
      console.error("Audio buffer not loaded");
      return;
    }

    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const source = audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(audioContext.destination);
    source.start(0);

    // Wait for audio playback to complete
    await new Promise((resolve) => {
      source.onended = resolve;
    });
  };
  const handleDownload = async () => {
    try {
      const response = await fetch(
        `https://core-api-jfm3errqea-el.a.run.app/api/audio/${audioId}`
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      setAudioUrl(url);

      const link = document.createElement("a");
      link.href = url;
      link.download = `audio_${audioId}.wav`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading audio:", error);
      // Handle error as needed
    }
  };

  return (
    <div>
      <h2>Audio Player</h2>
      <button onClick={loadAudio}>Load Audio</button>
      <button onClick={playAudio} disabled={!audioBuffer}>
        Play Audio
      </button>
      <button onClick={handleDownload}>Download Audio</button>
      {audioUrl && <audio controls src={audioUrl} />}
    </div>
  );
};

export default AudioPlayer;
