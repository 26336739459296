const initialState = {
    candidateDetails: null,
    selectedTestDetails: null,
  };
  
  const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_CANDIDATE_DETAILS":
        return {
          ...state,
          candidateDetails: action.payload,
        };
      case "SET_SELECTED_TEST_DETAILS":
        return {
          ...state,
          selectedTestDetails: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default dashboardReducer;
  