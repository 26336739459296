// CountdownTimer.jsx
import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Typography from "@mui/material/Typography";

const CountdownTimer = ({
  isPlaying,
  duration,
  size,
  strokeWidth,
  colors,
  onComplete,
  pkey,
  text,
}) => (
  <CountdownCircleTimer
    isPlaying={isPlaying}
    duration={duration}
    size={size}
    strokeWidth={strokeWidth}
    colors={colors}
    onComplete={onComplete}
    key={pkey}
    rotate={0}
  >
    {({ remainingTime }) => (
      <Typography
        variant="body2"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "15px",
          //   color: remainingTime === 0 ? "#A30000" : "#4CAF50",
        }}
      >
        {`${text}: ${Math.floor(remainingTime / 60)}:${remainingTime % 60}`}
      </Typography>
    )}
  </CountdownCircleTimer>
);

export default CountdownTimer;
