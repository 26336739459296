import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import CustomCard from "../BaseComponents/CustomCard";
import CustomButton from "../BaseComponents/CustomButton";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
const ProfilePage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [reset, setreset] = useState(true);
  const handleResetMail = () => {
    setreset(false);
  };
  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 8,
      }}
    >
      <CustomCard>
        {reset ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h5" color="text.primary" gutterBottom>
              Profile
            </Typography>
          </div>
        ) : (
          <></>
        )}
        {user && reset ? (
          <>
            <Typography variant="body1" color="text.secondary" paragraph>
              <strong>Name:</strong> {user.fullName}
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              <strong>Email:</strong> {user.email}
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              <strong>Phone Number:</strong> {user.phoneNumber}
            </Typography>
            {user.institute ? (
              <Typography variant="body1" color="text.secondary" paragraph>
                <strong>Institute:</strong> {user.institute}
              </Typography>
            ) : (
              <Typography variant="body1" color="text.secondary" paragraph>
                <strong>occupation:</strong> {user.occupation}
              </Typography>
            )}
          </>
        ) : (
          <Typography variant="body1" color="text.secondary" paragraph>
            <b>
              An email has been sent to you with a link to reset your password.
              please check
            </b>
          </Typography>
        )}
      </CustomCard>
    </Container>
  );
};

export default ProfilePage;
