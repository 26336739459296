// Filename - App.js
import React from "react";
const App = () => {
  return (
    <>
      <div
        style={{
          backgroundImage: "../../assets/PXL_20231217_083837261~2.jpg",
          // backgroundSize: "cover",
          width: "100%",
          height: "400px", // Example dimensions
        }}
      >
        <h1>GeeksForGeeks</h1>
      </div>
    </>
  );
};

export default App;
