import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute.js";
import HomePage from "./pages/HomePage";
import ContactUsPage from "./pages/ContactUsPage";
import AboutUsPage from "./pages/AboutUsPage";
import SuccessPage from "./pages/SuccessPage";
import FailurePage from "./pages/FailurePage";
import SignUp from "./pages/SignUpPage";
import SignIn from "./pages/SignInPage";
import Dashboard from "./pages/DashBoardPage";
import FileUploadPage from "./pages/FileUploadPage";
import QuestionPage from "./pages/QuestionsUploadPage";
import TestDetails from "./pages/TestDetailsPage.js";
import TestPage from "./pages/TestPage";
import ForgotPassword from "./pages/ForgotPassword.js";
import ProfilePage from "./pages/ProfilePage.js";
import ResetPassword from "./components/Auth/ResetPassword.js";
import AutoClickAndNavigateOnRefresh from "./components/Test/TestRefresh.js";
import AudioPlayer from "./components/Test/AudioPlayer.js";
import NotFound from "./pages/NotFound.js";

function App() {
  const token = localStorage.getItem("token");

  const routes = [
    {
      path: "/",
      element: token ? (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      ) : (
        <HomePage />
      ),
    },
    {
      path: "/home",
      element: token ? (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      ) : (
        <HomePage />
      ),
    },
    {
      path: "/contact",
      element: token ? (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      ) : (
        <ContactUsPage />
      ),
    },
    {
      path: "/about",
      element: token ? (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      ) : (
        <AboutUsPage />
      ),
    },
    { path: "/success", element: <SuccessPage /> },
    { path: "/failure", element: <FailurePage /> },
    {
      path: "/signup",
      element: token ? (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      ) : (
        <SignUp />
      ),
    },
    {
      path: "/signin",
      element: token ? (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      ) : (
        <SignIn />
      ),
    },
    {
      path: "/dashboard",
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: "/nimda",
      element: (
        <ProtectedRoute>
          <FileUploadPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/nimdaquestions",
      element: (
        <ProtectedRoute>
          <QuestionPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/testdetails",
      element: (
        <ProtectedRoute>
          <TestDetails />
        </ProtectedRoute>
      ),
    },
    {
      path: "/testpage",
      element: (
        <ProtectedRoute>
          <TestPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/audioplayer/:id",
      element: (
        <ProtectedRoute>
          <AudioPlayer />
        </ProtectedRoute>
      ),
    },
    { path: "/forgot-password", element: <ForgotPassword /> },
    { path: "/reset-password", element: <ResetPassword /> },
    {
      path: "/AutoClickAndNavigateOnRefresh",
      element: <AutoClickAndNavigateOnRefresh />,
    },
    {
      path: "/ProfilePage",
      element: (
        <ProtectedRoute>
          <ProfilePage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/404",
      element: token ? (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      ) : (
        <NotFound />
      ),
    },
    {
      path: "*",
      element: token ? (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      ) : (
        <NotFound />
      ),
    },
  ];

  return (
    <AuthProvider>
      <Router>
        <Routes>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
