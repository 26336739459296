import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import CustomCard from "../BaseComponents/CustomCard";
import CustomButton from "../BaseComponents/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { setTestDetails } from "../../redux/actions/testDetailsActions";
import { fetchTestDetails } from "../../api";

const TestDetails = () => {
  const dispatch = useDispatch();
  const { testDetails } = useSelector((state) => state.testDetails);
  const token = localStorage.getItem("token");
  const selectedTestDetails = useSelector(
    (state) => state.dashboard.selectedTestDetails
  );
  const { company_id, position_id, test_key } = selectedTestDetails || {};

  const navigate = useNavigate();

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const data = await fetchTestDetails(
          company_id,
          position_id,
          test_key,
          token
        );
        dispatch(setTestDetails(data));
        // if (data) {
        //   await navigator.permissions.reset();
        //   window.location.reload(true);
        // }
      } catch (error) {
        console.error("Error fetching test details:", error);
      }
    };

    if (company_id && position_id && test_key) {
      fetchDetails();
    }
  }, [company_id, position_id, test_key, dispatch]);

  // const handleStartTest = () => {
  //   navigate(`/testpage`);
  // };
  const handleStartTest = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: "microphone",
      });

      if (permissionStatus.state === "denied") {
        alert("Please enable audio permissions in your browser settings.");
        return;
      }
      await navigator.mediaDevices.getUserMedia({ audio: true });
      // Request full-screen access reliably
      const element = document.documentElement;

      if (element.requestFullscreen) {
        await element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        await element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        await element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        await element.msRequestFullscreen();
      }
      navigate("/testpage");
    } catch (error) {
      console.error(
        "Error requesting permissions or opening full-screen:",
        error
      );
      navigate("/dashboard");
    }
  };

  const handleBack = () => {
    navigate(`/dashboard`);
  };

  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 8,
      }}
    >
      <CustomCard>
        <Typography variant="h5" color="text.primary" gutterBottom>
          Test Details
        </Typography>
        {testDetails ? (
          <>
            <Typography variant="body1" color="text.secondary" paragraph>
              {testDetails.description}
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              Number of Questions:
              <strong>
                {testDetails.questionCount.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })}
              </strong>
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              Reading time for each question:<strong> 45 Seconds</strong>
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              Answering time for each question:<strong> 45 Seconds</strong>
            </Typography>
          </>
        ) : (
          <Typography variant="body1" color="text.secondary" paragraph>
            Loading test details...
          </Typography>
        )}
        <div sx={{ display: "flex", justifyContent: "flex-end" }}>
          <CustomButton
            onClick={handleBack}
            color="#191970"
            sx={{ width: "35%", marginLeft: 15, marginRight: 2 }}
          >
            Back
          </CustomButton>
          <CustomButton
            onClick={handleStartTest}
            color="#6A5ACD"
            sx={{ width: "35%" }}
          >
            Start Test
          </CustomButton>
        </div>
      </CustomCard>
    </Container>
  );
};

export default TestDetails;
