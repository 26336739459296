import React from "react";

import ContactUs from "../components/ContactUs/ContactUs";
import CustomNavbar from "../components/NavBar/CustomNavbar";
function ContactUsPage() {
  return (
    <div className="App">
      <CustomNavbar />
      <ContactUs />
    </div>
  );
}

export default ContactUsPage;
