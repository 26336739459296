import React from "react";
import Button from "@mui/material/Button";

const CustomButton = ({
  onClick,
  fullWidth,
  color,
  children,
  disabled,
  sx,
}) => (
  <Button
    variant="contained"
    disabled={disabled}
    fullWidth={fullWidth}
    sx={{
      borderRadius: 10,
      mt: 2,
      backgroundColor: color, // Use the specified color
      color: "#ECECEC",
      ...sx, // Additional styles
    }}
    onClick={onClick}>
    {children}
  </Button>
);

export default CustomButton;
