import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css"; // Import your global CSS file here
import { AuthProvider } from "./contexts/AuthContext";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store"; // Import your Redux store configuration
import { Provider } from "react-redux"; // Import Provider from react-redux

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <AuthProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </AuthProvider>
  </React.StrictMode>
);
