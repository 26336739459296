// RecordingPopup.js
import React, { useEffect, useState } from "react";

const RecordingPopup = ({ message, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, 2000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div
      style={{
        position: "absolute",
        top: "20%",
        left: "50%",
        width: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 999,
        display: isVisible ? "block" : "none",
        padding: "10px",
        border: "1px solid #ccc",
        borderRadius: "40px",
        backgroundColor: "#ECECEC",
        fontSize: "22px",
      }}>
      {message}
    </div>
  );
};

export default RecordingPopup;
