// AuthContext.js

import React, { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    (async () => {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        const userData = await JSON.parse(storedUser);
        setUser(userData);
      }
    })();
  }, []);

  const login = (userData) => {
    setUser(userData);
    // Store user in localStorage
    localStorage.setItem("user", JSON.stringify(userData));
  };

  const logout = () => {
    // Remove user from localStorage
    setUser(null);
    localStorage.clear();
    window.location.reload();
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
