import React, { useState } from "react";
import { signIn } from "../../api";
import { Navigate, Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import CustomCard from "../BaseComponents/CustomCard"; // Import your custom card component
import "./SignIn.css";
import img from "../../assets/login.jpg";

const SignIn = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [submitStatus, setSubmitStatus] = useState("");
  const [failureMessage, setFailureMessage] = useState("");
  const { login } = useAuth();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await signIn(formData);

      if (response.success) {
        setSubmitStatus("success");
        login(response.user);
        localStorage.setItem("token", response.token);
      }
    } catch (error) {
      setSubmitStatus("failure");
      setFailureMessage(
        error.response?.data.error || "An error occurred during sign-in."
      );
      console.error("Error signing in:", error.message);
    }
  };

  if (submitStatus === "success") {
    return <Navigate to="/dashboard" replace />;
  }
  return (
    <div style={{ display: "flex" }}>
      <img
        src={img}
        alt="home"
        style={{
          marginTop: "3%",
          marginLeft: "10%",
          width: "43%",
          borderRadius: "0%",
        }}
      />
      <CustomCard
        customStyles={{ marginTop: "3%", width: "40%", borderRadius: "0%" }}
      >
        <div
          style={{
            alignContent: "center",
            marginBottom: "30px",
          }}
        >
          <h2>Sign In</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email" className="form-label">
              Email:
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password" className="form-label">
              Password:
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          {submitStatus === "failure" && (
            <p className="text-danger mt-2">{failureMessage}</p>
          )}
          <button type="submit" className="btn btn-primary btn-block">
            Sign In
          </button>
          <div className="mt-3">
            <Link to="/forgot-password">
              <button className="btn btn-link">Forgot Password?</button>
            </Link>
          </div>
        </form>
      </CustomCard>
    </div>
  );
};

export default SignIn;
