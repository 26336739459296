import React from "react";
import CustomNavbar from "../components/NavBar/CustomNavbar";
import TestPageComponent from "../components/Test/TestPage";

function TestPage() {
  return (
    <div className="App">
      <CustomNavbar />
      <TestPageComponent />
    </div>
  );
}

export default TestPage;
